<template>
  <div class="relative">
    <div
      ref="readMoreContainer"
      v-html="html"
      :class="`rich-text hyphens-auto ${showFullText ? '' : linesToClamp}`"
    />
    <button
      v-if="showButton"
      aria-label="Show more"
      :class="`${showFullText ? 'relative' : 'absolute px-2'} bottom-0 right-0 text-brand bg-white`"
      @click="showFullText = !showFullText"
    >
      {{ showFullText ? 'show less' : '[...]' }}
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  lines: {
    type: [Number, String],
    default: 3
  },
  html: {
    type: String,
    default: ''
  },
})
const readMoreContainer = ref(null)
const showFullText = ref(false)
const showButton = ref(false)
const linesToClamp = computed(() => `line-clamp-${props.lines}`)

const isTextClamped = el => el.scrollHeight > el.clientHeight

onMounted(() => {
  showButton.value = isTextClamped(readMoreContainer.value)
})

</script>
